
import { Vue, Component } from 'vue-property-decorator'

@Component({})
export default class HeaderBlock extends Vue {
  public locales = ['en', 'ru']

  openForm() {
    this.$emit('open-form')
  }

  public get curLocale() {
    return this.$i18n.locale
  }

  public setLocale(locale: string) {
    console.debug('%c setLocale(%s)', 'color:magenta;', locale)
    this.$i18n.locale = locale

    localStorage.setItem('locale', locale)

    console.debug(this.$i18n)
  }
}
