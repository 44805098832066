
import { Component, Vue, Watch } from 'vue-property-decorator'
// @ts-ignore
import anime from 'animejs'
// import Inputmask from 'inputmask'
import { ILandingOrder, IProductSentencesResponse } from '@/interfaces'
import { dispatchGetProductSentencesSet, dispatchSubmitLandingOrder } from '@/store/main/actions'
import IconClose from '@/components/IconClose.vue'

@Component({
  components: { IconClose },
})
export default class FormBlock extends Vue {
  overlay: any = null
  form: any = null

  public loadingState: any = {
    submit: false,
  }

  public timeoutId: number = 0
  public addressError: boolean = false
  public latinRegex = /^[a-zA-Z\s-]*$/;
  public latinReplaceRegex = /[^a-zA-Z\s-]/g;
  public addressRegex = /^[a-zA-Z0-9\s#"'()+-:]*$/
  public addressReplaceRegex = /[^a-zA-Z0-9\s#"'()+-:]/g

  public order: ILandingOrder = {
    products: [],
    prePhone: '',
    phone: '',
    country: '',
    city: '',
    delivery_address: '_',
    // index: '',
    email: '',
    full_name: '',
    first_name: '',
    last_name: '',
    promocode: '',
    starlink_kit: false,
  }
  public formError: boolean = false
  public emailExists: boolean = false
  public phoneInvalid: boolean = false
  public phoneExists: boolean = false
  public sentencesResponse: IProductSentencesResponse = {
    products: [],
    promocode: null,
  }

  @Watch('order.promocode')
  onChangePromocode(val: string, oldVal: string) {
    // console.debug('%c @Watch.onChangePromocode(val: %s, oldVal: %s)', 'color:gold;', val, oldVal)
    this.order.promocode = val.replace(/ /g, '')
  }

  get mainProduct() {
    let mainProduct: any = null;
    for (let i in this.sentencesResponse.products) {
      console.log
      if (Number(i) === 0) {
        mainProduct = this.sentencesResponse.products[i];
      }
    }
    return mainProduct;
  }
  get starlinkProduct() {
    let mainProduct: any = null;
    for (let i in this.sentencesResponse.products) {
      if (Number(i) === 1) {
        mainProduct = this.sentencesResponse.products[i];
      }
    }
    return mainProduct;
  }

  get orderAmount() {
    const mainPrice = this.mainProduct ? this.mainProduct.price : 0;
    const starlinkPrice = this.starlinkProduct && this.order.starlink_kit ? this.starlinkProduct.price : 0;
    return mainPrice + starlinkPrice;
  }

  public async handleUpdatePromocode() {
    console.debug('%c handleUpdatePromocode(%s)', 'color:magenta;font-size:18px;', this.order.promocode)

    const response = await dispatchGetProductSentencesSet(
      this.$store, {
        // product_sentences_set: 'landing',
        product_sentences_set: 'dexnodeWithKey',
        promocode: this.order.promocode,
      },
    );
    if (response) {
      this.sentencesResponse = response;
    }
  }

  get validateForm() {
    this.formError = !this.order.first_name
      || !this.order.last_name
      || !this.order.phone
      || !this.order.email
      || !this.order.country
      || !this.order.city
      // || !this.order.delivery_address
      // || !this.order.index
      || !this.order.promocode
      || !this.sentencesResponse.promocode

    return !this.formError
  }

  public async handleSubmit() {
    console.log('%c handleSubmit(%s)', 'color:gold;', JSON.stringify(this.order, null, 4));

    if (this.validateForm) {
      this.emailExists = false;
      this.phoneExists = false;

      this.loadingState.submit = true;
      // this.order.products.push(this.mainProduct ? this.mainProduct.id : 1);

      // Отправить айдишники всех продуктов
      this.order.products = this.sentencesResponse.products.map((product) => product.id)

      // if (this.order.starlink_kit) {
      //   this.order.products.push(this.starlinkProduct ? this.starlinkProduct.id : 2);
      // }
      const response = await dispatchSubmitLandingOrder(this.$store, this.order);
      if (response.status === 200 || response.status === 201) {
        // this.$router.push(`/dashboard?open_order=${response.data.order.id}`);
        // TODO Временно убрал. Разобраться, как сбрасывать все стили, при изменении роута.
        window.location.pathname = `/dashboard?open_order=${response.data.order.id}`
      } else if (response.status === 400) {
        if (response.data.email) {
          this.$toast.error(this.$t('Email is already exists').toString());
          this.emailExists = true;
        } else if (response.data.phone) {
          this.$toast.error(this.$t('Phone is already exists').toString());
          this.phoneExists = true;
        } else {
          this.$toast.error(this.$t('Error').toString());
        }
      } else {
        this.$toast.error(this.$t('Error').toString());
      }
      this.loadingState.submit = false;
    }
  }
  public validatePhone(payload: any) {
    console.debug('%c validatePhone(%s)', 'color:gold;', this.order.prePhone)
    // console.debug('%c validatePhone(%s)', 'color:lime;', JSON.stringify(payload, null, 4))

    this.order.phone = payload.isValid ? payload.formattedNumber : ''

    // this.phoneInvalid = false
    // if (!!this.order.prePhone) {
    //   const phone = this.order.prePhone.replace(/[^0-9]/g, '')
    //   if (phone.length === 10) {
    //     this.order.phone = phone
    //     return
    //   }
    // }
    // this.order.phone = ''
    // this.phoneInvalid = true
  }

  public showForm() {
    if (this.overlay && this.form) {
      const tl = anime.timeline({
        duration: 400,
        easing: 'linear',
      });

      tl.add({
        targets: this.overlay,
        opacity: [0, 1],
        begin: () => {
          this.overlay.style.zIndex = 999
        },
      });

      tl.add({
        targets: this.form,
        opacity: [0, 1],
        begin: () => {
          this.form.style.zIndex = 10000
        },
      });
    }
  }

  public closeForm() {
    if (this.overlay) {
      this.overlay.removeEventListener('click', this.closeForm)
    }
    if (this.overlay && this.form) {
      const tl = anime.timeline({
        duration: 400,
        easing: 'linear',
      });

      tl.add({
        targets: this.form,
        opacity: [1, 0],
        complete: () => {
          this.form.style.zIndex = -10000
        },
      });

      tl.add({
        targets: this.overlay,
        opacity: [1, 0],
        complete: () => {
          this.overlay.style.zIndex = -999
        },
      });
    }

    setTimeout(() => this.$emit('close-form'), 500)
  }

  // public checkCountry() {
  //   const country = this.order.country
  //   if (!this.latinRegex.test(country)) {
  //     this.order.country = country.replace(this.latinReplaceRegex, '')
  //   }
  // }

  // public checkCity() {
  //   const city = this.order.city
  //   if (!this.latinRegex.test(city)) {
  //     this.order.city = city.replace(this.latinReplaceRegex, '')
  //   }
  // }

  // public checkAddress() {
  //   const address = this.order.delivery_address
  //   if (!this.addressRegex.test(address)) {
  //     this.addressError = true
  //     clearTimeout(this.timeoutId)
  //     this.timeoutId = setTimeout(() => {
  //       this.addressError = false
  //     }, 2000)
  //
  //     setTimeout(() => {
  //       this.order.delivery_address = address.replace(this.addressReplaceRegex, '')
  //     }, 300)
  //   }
  // }

  beforeDestroy() {
    console.debug('%c FormBlock.vue is beforeDestroy!', 'color:orangered;')
    const html = document.querySelector('html')
    if (html) {
      html.setAttribute('style', 'overflow: auto')
    }
  }

  async mounted() {
    console.debug('%c FormBlock.vue is mounted!', 'color:lime;')
    const html = document.querySelector('html')
    if (html) {
      html.setAttribute('style', 'overflow: hidden')
    }

    // console.debug('%c FormBlock.vue is mounted!', 'color:gold;font-size:18px;')

    this.overlay = document.getElementById('overlay')
    this.form = document.getElementById('form')

    if (this.overlay) {
      this.overlay.addEventListener('click', this.closeForm)
    }

    this.showForm()

    // Маска для номера телефона в форме
    // Inputmask({ mask: '+99 (999) 999-99-99' }).mask(document.getElementById('phone_mask'))

    if (this.$route.query.email) {
      this.order.email = String(this.$route.query.email)
    }
    if (this.$route.query.promocode) {
      this.order.promocode = String(this.$route.query.promocode)
    }

    await this.handleUpdatePromocode()
    this.$emit('change-price', this.mainProduct ? this.mainProduct.price : 1499)

    setTimeout(() => {
      const inputs = document.querySelectorAll('.input-tel__input')
      if (inputs.length) {
        inputs[0].setAttribute('maxLength', '15')
      }
    }, 700)
  }
}
