
import { Component, Vue } from 'vue-property-decorator'

import SvgLinkedIn from '@/components/Landing/svg/SvgLinkedIn.vue'
import SvgTwitter from '@/components/Landing/svg/SvgTwitter.vue'
import SvgTelegram from '@/components/Landing/svg/SvgTelegram.vue'
import SvgFacebook from '@/components/Landing/svg/SvgFacebook.vue'
import SvgInstagram from '@/components/Landing/svg/SvgInstagram.vue'

@Component({
  components: {
    SvgLinkedIn,
    SvgTwitter,
    SvgTelegram,
    SvgFacebook,
    SvgInstagram,
  },
})
export default class FooterBlock extends Vue {
  scrollUp() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  mounted() {
    // console.debug('%c FooterBlock.vue is mounted!', 'color:lime;font-size:18px;')
  }
}
