
import { Component, Vue } from 'vue-property-decorator'
import anime from 'animejs'

import SvgLinkedIn from '@/components/Landing/svg/SvgLinkedIn.vue'
import SvgTwitter from '@/components/Landing/svg/SvgTwitter.vue'
import SvgTelegram from '@/components/Landing/svg/SvgTelegram.vue'
import SvgFacebook from '@/components/Landing/svg/SvgFacebook.vue'
import SvgInstagram from '@/components/Landing/svg/SvgInstagram.vue'

@Component({
  components: {
    SvgLinkedIn,
    SvgTwitter,
    SvgTelegram,
    SvgFacebook,
    SvgInstagram,
  }
})
export default class SocialsBlock extends Vue {
  mounted() {
    // console.debug('%c SocialsBlock.vue is mounted!', 'color:lime;font-size:18px;')

    const btn = document.getElementById('socials__btn');
    const socialsList = document.getElementById('socials__list');

    let isAnimated = true;
    let inFocus = false;

    if (btn && socialsList) {
      btn.addEventListener('focusout', () => {
        inFocus = false;

        hideSocials();
      });

      const hideSocials = () => {
        // if (inFocus || !isAnimated) return

        if (!isAnimated) return;

        isAnimated = false;

        const tl = anime.timeline({
          duration: 500,
        });

        if (window.innerWidth < 1500) {
          tl.add({
            targets: '.socials__item',
            translateY: [-60, 0],
            delay: anime.stagger(200),
          });

          tl.add({
            targets: btn,
            translateY: [70, 0],
            opacity: 1,
          });
        } else {
          tl.add({
            targets: '.socials__item',
            translateX: [60, 0],
            delay: anime.stagger(200),
          });

          tl.add({
            targets: btn,
            translateX: [-70, 0],
            opacity: 1,
          });
        }

        tl.finished.then(() => {
          isAnimated = true;

          if (inFocus) {
            showSocials();
          }
        });
      };

      const showSocials = async () => {
        if (!isAnimated) return;

        isAnimated = false;

        const tl = anime.timeline({
          duration: 500,
        });

        if (window.innerWidth < 1500) {
          tl.add({
            targets: btn,
            translateY: 60,
            opacity: 0,
          });

          tl.add({
            targets: '.socials__item',
            translateY: [0, -60],
            delay: anime.stagger(200),
          });
        } else {
          tl.add({
            targets: btn,
            translateX: -70,
            opacity: 0,
          });

          tl.add({
            targets: '.socials__item',
            translateX: [0, 60],
            delay: anime.stagger(200),
          });
        }

        tl.finished.then(() => {
          isAnimated = true;

          if (!inFocus) {
            hideSocials();
          }
        });
      };

      btn.addEventListener('mouseover', () => {
        inFocus = true;

        showSocials();
      });

      socialsList.addEventListener('mouseover', () => (inFocus = true));

      socialsList.addEventListener('mouseleave', () => {
        inFocus = false;

        setTimeout(() => {
          hideSocials();
        }, 0);
      });
    }

  }
}
